
import { Options, Vue } from 'vue-class-component';
import LinkButton from '@/components/LinkButton.vue';
import { PartnerData } from '@/models/PartnerData';
import gql from "graphql-tag";
import { GQLListResult } from '@/models/GQLListResult';
import { EventType } from "@/models/EventType";

@Options({
    components: {
        LinkButton,
    },
    apollo: {
        partners: {
            query: gql`query Partners {
                partners(pagination: { page: 1, pageSize: 100 }) {
                    data {
                        id
                        attributes {
                            url
                            name
                            logo {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    },
                    meta {
                        pagination {
                            page
                            pageSize
                            total
                            pageCount
                        }
                    }
                }
            }`,
        },
    },
    watch: {
        partners(newValue) {
            console.log(newValue)
        }
    }
})
export default class Home extends Vue {
    eventTypes = Object.values(EventType);
    countries = ["Australia", "Canada", "UK", "USA"];
    partners: GQLListResult<PartnerData> | null = null;
    filters: any = {};

    responsiveCarouselOptions = [
			{
				breakpoint: '1280px',
				numVisible: 2,
				numScroll: 2
			},
			{
				breakpoint: '980px',
				numVisible: 1,
				numScroll: 1
			}
		]

    get partnersList() {
        return this.partners?.data ?? [];
    }

    created() {
      this.resetFilters();
    }

    getUrlDate(date: Date) {
      return date.toLocaleString("default").slice(0, 10);
    }

    get searchEventsUrl() {
      const country = (this.filters.country || "usa").toLocaleLowerCase();

      const url = new URL(window.location.origin + `/events/${country}`);

      if (this.filters.event_type?.length > 0) {
        url.searchParams.append("eventType", this.filters.event_type.join(","));
      }

      if (this.filters.state) {
        url.searchParams.append("state", this.filters.state);
      }

      if (this.filters.start_date.start) {
        url.searchParams.append(
          "startDate",
          this.getUrlDate(this.filters.start_date.start)
        );
      }

      if (this.filters.start_date.end) {
        url.searchParams.append(
          "endDate",
          this.getUrlDate(this.filters.start_date.end)
        );
      }

      return url.pathname + url.search;
    }

    resetFilters() {
      const startFilter = this.filters?.start_date ?? {
        value: { start: null, end: null },
        matchMode: "dateRangeFilter",
      };
      startFilter.value.start = null;
      startFilter.value.end = null;

      this.filters = {
        search: "",
        event_type: null,
        state: "",
        country: "USA",
        start_date: { start: null, end: null },
      };
    }
}
