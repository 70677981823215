import { createApp, h } from 'vue';
import { createApolloProvider } from "@vue/apollo-option";
import App from './App.vue';
import router from './router';
import apolloClient from './vue-apollo';
import PrimeVue from 'primevue/config';
import VueGtag from "vue-gtag";

import States from './models/States';

import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Carousel from 'primevue/carousel';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

import Divider from 'primevue/divider';
import Password from 'primevue/password';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { FilterService } from 'primevue/api';
FilterService.register('dateRangeFilter', (startDate: string, dateRange: { start?: Date, end?: Date }) => {
    if (!dateRange?.start && !dateRange?.end) return true;
    if (!startDate) return false;

    const intValue = parseInt(startDate.replace(/-/g, ''));
    const startIntValue = dateRange.start ? parseInt(dateRange.start.toISOString().slice(0, 10).replace(/-/g, '')) : 0;
    const endIntValue = dateRange.end ? parseInt(dateRange.end.toISOString().slice(0, 10).replace(/-/g, '')) : 99991332;

    return startIntValue <= intValue && intValue <= endIntValue;
});

FilterService.register('stateFilter', (stateOrProvince: string, searchTerm: string) => {
    stateOrProvince = stateOrProvince?.toLowerCase();
    searchTerm = searchTerm?.toLowerCase();

    if (!searchTerm || searchTerm?.trim().length == 0) return true;

    // The global filter searches every field (name, state, etc). `fieldState` determines which (if any) state matches the current column value
    const fieldState = States.US.filter(x => x.code.toLowerCase() == stateOrProvince).pop();
    // `termState` determines which (if any) state matches the current search term (i.e. what the user types into the search box)
    const termState = States.US.filter(x => x.code.toLowerCase() == searchTerm).pop();

    if (termState) {
      return fieldState != null && termState.code == fieldState.code;
    }
    else if (fieldState) {
      return fieldState.code.toLowerCase() == searchTerm || fieldState.name.toLowerCase().includes(searchTerm);
    }
    else {
      return stateOrProvince.includes(searchTerm);
    }
});

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

const gtagProperties = {
    appName: 'The Daily Triangle',
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to: { path: string }) {
        return {
            page_title: to.path,
            page_path: to.path
        }
    },
    config: {
        id: "G-BZBSX3GF7Q",
    }
};

const app = createApp(App)
    .use(router)
    .use(apolloProvider)
    .use(VueGtag, gtagProperties, router)
    .use(PrimeVue);

app.component('MultiSelect', MultiSelect);
app.component('DataTable', DataTable);
app.component('InputText', InputText);
app.component('Carousel', Carousel);
app.component('Button', Button);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Password', Password);
app.component('Calendar', Calendar);
app.component('Dropdown', Dropdown);
app.component('Checkbox', Checkbox);

app.mount('#app');
