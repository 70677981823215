
import { Options, Vue, prop } from 'vue-class-component';

class Props {
    country: string = prop({
        required: true
    });
}

@Options({})
export default class About extends Vue.with(Props) {
    capitalizeFirstLetter(string: string) {
        if (string.length < 4) return string.toLocaleUpperCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
