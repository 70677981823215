import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SubmitForm from '../views/SubmitForm.vue'
import Events from '../views/Events.vue'
import About from '../views/About.vue'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/submit',
    name: 'SubmitForm',
    component: SubmitForm
  },
  {
    path: '/events/:country',
    name: 'Events',
    component: Events,
    props: true,
  },
  {
    path: '/schedule/:country',
    name: 'Schedule',
    component: About,
    props: true,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
