import { ApolloClient, InMemoryCache, createHttpLink, HttpLink } from '@apollo/client/core'

// HTTP connection to the API
const httpLink = createHttpLink({
    uri: process.env.VUE_APP_GRAPHQL_URL || "http://localhost:1337/graphql",
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache
});

export default apolloClient;
