
import { Options, Vue, prop } from 'vue-class-component';
import { EventData } from '@/models/EventData';

class Props {
    event?: EventData = prop({
        required: false,
    });
}

@Options({})
export default class EventDetails extends Vue.with(Props) {
    get posterURL() {
        let link = this.event?.info_registration_link;
        const isInstagram = link?.startsWith("https://www.instagram.com")
        if (!link || !isInstagram) return null;
        if (!link.endsWith('/')) link += '/';

        return `${link}media/?size=l`;
    }

    get dateRangeDisplay() {
        return this.event ? this.getDateRangeDisplay(this.event) : "";
    }

    get locationDisplay() {
        const city = this.event?.city;
        const state = this.event?.state_or_province;

        if (!city || !state) return city ?? state ?? "";
        else if (city.includes(',')) return city;
        else return `${city}, ${state}`;
    }

    parseDate(str: string) {
        if (!str) return new Date();
        
        str = str.replace(/-/g, '');
        const y = parseInt(str.substring(0, 4));
        const m = parseInt(str.substring(4, 6)) - 1;
        const d = parseInt(str.substring(6, 8));
        const date = new Date(y, m, d);
        const isValid = (date.getFullYear() === +y && date.getMonth() === +m && date.getDate() === +d);
        if (!isValid) console.warn('Invalid date parsed', str);
        return date;
    }

    getDateDisplay(date: Date | string) {
        date = (typeof date === 'string') ? this.parseDate(date) : date;
        const month = date.toLocaleString('default', { month: 'long' });
        return `${month} ${date.getDate()}`;
    }

    getDateRangeDisplay(data: EventData) {
        if (data.start_date == null && data.end_date == null) return '';
        else if (data.end_date && data.end_date != data.start_date) {
            const startDate = this.parseDate(data.start_date);
            const endDate = this.parseDate(data.end_date);
            const isSameMonth = startDate.getMonth() == endDate.getMonth();

            const startDisplay = this.getDateDisplay(startDate);
            const endDisplay = isSameMonth ? endDate.getDate() : this.getDateDisplay(endDate);
            return `${startDisplay} - ${endDisplay}`;
        }
        else return this.getDateDisplay(data.start_date);
    }
}
