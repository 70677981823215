
import { Options, Vue, prop } from 'vue-class-component';
import { email, required } from "@vuelidate/validators";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import gql from "graphql-tag";
import { Ref } from 'vue';

@Options({
    validations() {
        return {
            name: {
                required
            },
            email: {
                required,
                email
            },
            event_type: {
                required
            },
            country: {
                required
            },
            link: {
                required
            }
        }
    }
})
export default class SubmitForm extends Vue {
    name: string = '';
    email: string = '';
    event_type: string | null = null;
    country: string | null = null;

    who: string = '';
    what: string = '';
    when: string = '';
    where: string = '';
    link: string = '';

    v$: Ref<Validation<ValidationArgs<any>, any>> = useVuelidate();
    showMessage: boolean = false;
    submitted: boolean = false;

    countries = ['Australia', 'Canada', 'UK', 'USA', 'Other'];
    eventTypes = ['Camp', 'Competition', 'Seminar'];

    sendEvent() {
        this.$apollo.mutate({
            // Query
            mutation: gql`mutation ($name: String!, $email: String!, $event_type: ENUM_EVENT_EVENT_TYPE !, $country: ENUM_EVENT_COUNTRY!, $who: String!, $what: String!, $when: String!, $where: String!, $link: String!) {
                createEvent(data: {
                    name: $name,
                    submitters_email: $email,
                    event_type: $event_type,
                    country: $country,
                    who: $who,
                    what: $what,
                    when: $when,
                    where: $where,
                    info_registration_link: $link
                }) {
                    data {
                        id
                    }
                }
            }`,
            // Parameters
            variables: {
                name: this.name,
                email: this.email,
                event_type: this.event_type,
                country: this.country,
                who: this.who,
                what: this.what,
                when: this.when,
                where: this.where,
                link: this.link
            },
        }).then((data) => {
            // Result
            console.log(data)
        }).catch((error) => {
            // Error
            console.error(error)
        })
    }

    handleSubmit(isFormValid: boolean) {
        this.submitted = true;

        if (isFormValid) {
            this.sendEvent();
            this.toggleDialog();
        }
    }

    toggleDialog() {
        this.showMessage = !this.showMessage;

        if (!this.showMessage) {
            this.resetForm();
        }
    }

    resetForm() {
        this.name = '';
        this.email = '';
        this.event_type = null;
        this.country = null;

        this.who = '';
        this.what = '';
        this.when = '';
        this.where = '';

        this.submitted = false;
    }
}
