
import { Options, Vue, prop } from 'vue-class-component';

class Props {
    solid?: boolean = prop({
        required: false,
    });

    to: string = prop({
        required: true,
    });
}

@Options({})
export default class LinkButton extends Vue.with(Props) {
}
