import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22a727fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "event-details-wrapper" }
const _hoisted_2 = { class: "details-wrapper" }
const _hoisted_3 = {
  class: "left-stack",
  style: {"flex":"1 1 25%"}
}
const _hoisted_4 = {
  class: "w-stack",
  style: {"flex":"1 1 auto"}
}
const _hoisted_5 = { class: "more-info-container" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  class: "right-stack",
  style: {"flex":"1 1 25%"}
}
const _hoisted_8 = { style: {"font-size":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.dateRangeDisplay), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.event?.name), 1),
        _createElementVNode("span", null, "WHO: " + _toDisplayString(_ctx.event?.who), 1),
        _createElementVNode("span", null, "WHAT: " + _toDisplayString(_ctx.event?.what), 1),
        _createElementVNode("span", null, "WHEN: " + _toDisplayString(_ctx.event?.when), 1),
        _createElementVNode("span", null, "WHERE: " + _toDisplayString(_ctx.event?.where), 1),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.event?.info_registration_link)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.event?.info_registration_link,
                target: "_blank"
              }, " Link Here ", 8, _hoisted_6))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", null, _toDisplayString(_ctx.event?.time ?? "All day"), 1),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.locationDisplay), 1)
      ])
    ])
  ]))
}