EventDetails
import { Options, Vue, prop } from 'vue-class-component';
import EventDetails from '@/components/EventDetails.vue';
import EventType from '@/models/EventType';
import LinkButton from '@/components/LinkButton.vue';
import gql from "graphql-tag";
import { GQLListResult } from '@/models/GQLListResult';
import { EventData } from '@/models/EventData';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import States from '../models/States';
undefined

class Props {
    country: string = prop({
        required: true
    });
}

@Options({
    components: {
        EventDetails,
        LinkButton,
    },
    apollo: {
        events: {
            variables() {
                let $this = this as any;
                return {
                    page: $this.page,
                    country: this.countryKey
                }
            },
            query: gql`query Events ($page: Int!, $country: String!) {
                events(pagination: { page: $page, pageSize: 10000 }, filters: { country: { eq: $country } }) {
                    data {
                        id
                        attributes {
                            event_type
                            organization
                            name
                            city
                            state_or_province
                            country
                            info_registration_link
                            start_date
                            end_date
                            time
                            who
                            what
                            when
                            where
                        }
                    },
                    meta {
                        pagination {
                            page
                            pageSize
                            total
                            pageCount
                        }
                    }
                }
            }`,
        },
    },
    watch: {
        events(newValue, oldValue) {
            this.isLoading = false;
        },
        country(newValue, oldValue) {
            if (newValue !== oldValue) {
              this.isLoading = true;
              if (this.filters?.state_or_province?.value) {
                this.filters.state_or_province.value = null;
              }
            }
        }
    }
})
export default class Events extends Vue.with(Props) {
    events: GQLListResult<EventData> | null = null;
    page = 1;
    showMoreEnabled = true;
    isLoading = true;

    isModalVisible = false;
    modalTarget: EventData | null = null;

    filters: any = {};
    eventTypes = Object.values(EventType);

    get countryKey() {
        return this.capitalizeFirstLetter(this.country);
    }

    get countryDisplay() {
        if (this.country.startsWith('u')) return `the ${this.countryKey}`;
        else return this.countryKey;
    }

    get tableData() {
        const gqlEvents = this.events?.data ?? [];
        const events: EventData[] = gqlEvents.map(e => ({ ...e.attributes, id: (e as any).id }));

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const parseDate = this.parseDate;
        return events.filter(e => {
            const targetDate = e.end_date ?? e.start_date;
            if (!targetDate) return true;
            const date = parseDate(targetDate);
            return date >= today;
        });
    }

    get states() {
      const events = this.tableData;
      const uniqueStates = events.map(x => x.state_or_province).filter((value, index, self) => self.indexOf(value) === index).sort();
      return uniqueStates;
    }

    getTrimmed(values: string[], maxLength: number) {
      const joined = values.join(", ");
      if (joined.length > maxLength)
        return joined.substring(0, maxLength) + "...";
      else
        return joined;
    }

    showModal(data: EventData) {
        this.modalTarget = data;
        this.isModalVisible = true;
    }

     parseStandardDateOrtoNull(str: string) {
         const date = new Date(str);
         if (date instanceof Date && !isNaN(date as any)) {
             return date
         }
         return null;
     }

     setParamsFrom({endDate, startDate, eventType, state}: any) {
         if (typeof eventType === 'string' && eventType.length > 0) {
             const types = eventType.split(",").map(item => item.trim()).filter(item => !!item && this.eventTypes.includes(item as any));
             this.filters.event_type = { value: types.length > 0 ? types : null,  matchMode: FilterMatchMode.IN}
         }

         if (state) {
          state = state.toLowerCase();
          const matches = States.US.filter(x => x.code.toLowerCase() == state || x.name.toLowerCase().includes(state));
          this.filters.state_or_province.value = matches.map(x => x.code);
         }

         if (startDate || endDate) {
             const dateValue = {
              start: typeof startDate === 'string' ? this.parseStandardDateOrtoNull(startDate) : null,
              end: typeof endDate === 'string' ? this.parseStandardDateOrtoNull(endDate) : null
            };

             const startFilter = { value: dateValue, matchMode: "dateRangeFilter" };
             this.filters.start_date = startFilter;
         }
     }

     created() {
        this.resetFilters();
        this.setParamsFrom(this.$route.query);
        this.$router.replace({'query': undefined});
         // NOTE: really hacky, but reset clears so lets set url back to what it was
    }

    resetFilters() {
        const startFilter = this.filters?.start_date ?? { value: { start: null, end: null }, matchMode: "dateRangeFilter" };
        startFilter.value.start = null;
        startFilter.value.end = null;

        this.filters = {
            'global': { value: null, matchMode: "stateFilter" },
            'event_type': { value: null, matchMode: FilterMatchMode.IN },
            'start_date': startFilter,
            'state_or_province': { value: null, matchMode: FilterMatchMode.IN },
        }

    }

    capitalizeFirstLetter(string: string) {
        if (string.length < 4) return string.toLocaleUpperCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    showMore() {
        this.page++;
    }

    parseDate(str: string) {
        str = str.replace(/-/g, '');
        const y = parseInt(str.substring(0, 4));
        const m = parseInt(str.substring(4, 6)) - 1;
        const d = parseInt(str.substring(6, 8));
        const date = new Date(y, m, d);
        const isValid = (date.getFullYear() === +y && date.getMonth() === +m && date.getDate() === +d);
        if (!isValid) console.warn('Invalid date parsed', str);
        return date;
    }

    getDateDisplay(date: Date | string) {
        date = (typeof date === 'string') ? this.parseDate(date) : date;
        const month = date.toLocaleString('default', { month: 'long' });

        return `${month} ${date.getDate()}`;
    }

    getDateRangeDisplay(data: EventData) {
        if (data.start_date == null && data.end_date == null) return '';
        else if (data.end_date && data.end_date != data.start_date) {
            const startDate = this.parseDate(data.start_date);
            const endDate = this.parseDate(data.end_date);
            const isSameMonth = startDate.getMonth() == endDate.getMonth();

            const startDisplay = this.getDateDisplay(startDate);
            const endDisplay = isSameMonth ? endDate.getDate() : this.getDateDisplay(endDate);
            return `${startDisplay} - ${endDisplay}`;
        }
        else return this.getDateDisplay(data.start_date);
    }
}
